import React from 'react';
import styled from 'styled-components';
import  Divider  from '@material-ui/core/Divider';


const StyledDevider = styled(Divider)`
    margin-top : 20px;
    margin-bottom : 15px;
`;


const MarginDivider = () => {
    return  (
        <StyledDevider/>
    );
}

export default MarginDivider;