import React from 'react';
import styled from "styled-components";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Typography } from '@material-ui/core';
import { List, ListItem, Checkbox, ListItemText } from '@material-ui/core';


const StyledExperienceSection = styled.div`
    overflow : auto;
`;

const helpYouWith = [
    "Web Application Development",
    "Ecommerce Web Development",
    "Andoird/iOS Mobile Application Develoment",
    "Software &  Business Analysis",
    "Software Testing",
    "Website Design & Re-design",
    "Portfolio/Business Websites",
    "Hybrid Mobile Applicattion Development"
]


const HelpyYouWithSection = () => {
    return (
        <StyledExperienceSection>
            <Typography variant="button" display="block" gutterBottom>
                I can help you with....
            </Typography>
            <List>
                {helpYouWith.map((value) => {
                    const labelId = `checkbox-list-label-labelId`;
                    const labelValue = `⚉ ${value}`
                    return (
                        <ListItem>
                           <Typography variant="body1" display="block" gutterBottom>
                                {labelValue}
                            </Typography>
                        </ListItem>
                        
                    );
                })}
            </List>
        </StyledExperienceSection>
    )
}

export default HelpyYouWithSection;

