import React from "react";
import styled from "styled-components";
import { render } from "react-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Skills from './components/Skills';

import ProfileSection from "./components/ProfileSection";

const Layout = styled(Typography)`
  display : flex;
  background-color: #EDEDED;
  height: 100vh;
  align-items : center;
`;

const App = () => (
  <StylesProvider injectFirst>
    <Layout component='div'>
      <ProfileSection/>
    </Layout>
  </StylesProvider>
);

export default App;