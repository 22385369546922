import React from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { Typography, Button, Link, Icon } from '@material-ui/core';
import ContactInformation from './ContanctInformation';


const StyledContent = styled.div`
    display : flex;
    width : 100%;
    height: 90%;
    overflow-y : auto;
    background-color  : #FFFFFF;
`;

const StyleProfileSection = styled.div`
    margin : 5% 20% 0 20%;
`;



const StyledAvatar = styled(Avatar)`
    height : 100px;
    width : 100px;
    margin-bottom:15px;
`;


const ProfileSection = () => {
    return (
        <StyledContent>
            <StyleProfileSection>
                <StyledAvatar src="./src/resources/jtailor.jpeg">JT</StyledAvatar>
                <ContactInformation />
                <Link href="mailto: jenishtailor178@gmail.com" target="_blank">
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Let's chat
                    </Button>
                </Link>

            </StyleProfileSection>
        </StyledContent>
    );
}


export default ProfileSection;