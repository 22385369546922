import React, { Fragment } from "react";
import styled from "styled-components";
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Typography, Button, Link } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import MarginDivider from './MarginDivider'
import Skills from './Skills';
import Experience from './HelpYouWith';

const StyledLocationInformation = styled.div`
    margin-bottom : 10px
`

const StyledSubTitlte1 = styled(Typography)`
    coloor : '#545454'
    display: block
`;

const StyledButton = styled(Button)`
  background-color: #3f51b5;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  &:hover {
    background-color: #5469d4;
  }
  margin-bottom : 10px;
`;

const ContactInformation = () => {
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>Jay Tailor</Typography>
            <StyledSubTitlte1 variant="subtitle1" gutterBottom >Full Stack Developer 🙎🏽‍♂️- Problem solver 🤔- Data enthusiast 🗳</StyledSubTitlte1>
            <StyledLocationInformation>
                <Typography variant="subtitle3" gutterBottom>🌏 Ottawa - 🇨🇦Canada - 🇮🇳India</Typography>
            </StyledLocationInformation>
            <Fragment>
                <Link href="https://github.com/lakshya-8" color="inherit" target="_blank">
                    <GitHubIcon />
                </Link>
                <Link href="https://www.linkedin.com/in/jenishtailor/" color="inherit"  target="_blank">
                    <LinkedInIcon />
                </Link>
                <Link href="https://twitter.com/BeingHuman_J" color="inherit" target="_blank">
                        <TwitterIcon />
                </Link>
                </Fragment>
            <MarginDivider />
            <Skills />
            <MarginDivider />
            <Experience />

        </Fragment>

    );
}

export default ContactInformation;