import React, { Fragment } from 'react';
import styled from "styled-components";
import { Typography, Chip } from '@material-ui/core';


const STATIC_SKILLS = {
    skills: [
        "JavaScript", "Java", "React", "Vue.js","Redux", 'Akka', "Flutter", "spring-boot", "Groovy", "Grails","Node","Python"
    ]
}

const StyledSkillSection = styled.div`
    
`;

const StyledChip = styled(Chip)`
    margin: 5px
`;



const Skills = () => {
    const skills = STATIC_SKILLS.skills.map((skill) => (
        <StyledChip color="primary" clickable label={skill} />
    ));
    return (
        <Fragment>
            <Typography variant="button" display="block" gutterBottom>
                Skills
            </Typography>
            <StyledSkillSection>
                {skills}
            </StyledSkillSection>
        </Fragment>
    );
}

export default Skills;